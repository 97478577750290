/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
} from "reactstrap";
// eslint-disable-next-line object-curly-newline
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

// i18n
import { withTranslation } from "react-i18next";
import CustomPagination from "components/Common/CustomPagination";
import TableLoader from "components/Common/TableLoader";
import { checkAllBoxes } from "common/utils/checkAllBoxes";
// import Notification from "components/Common/Notification";
// import logo from "assets/images/logo-sm.svg";
import { captilazeFirstLetter } from "common/utils/manipulateString";
import Badge from "components/Common/Badge";
import formatDate from "helpers/formatDate";
import { fetchCredits } from "store/forexTransactions/credit/actions";
import AddCreditModal from "pages/Transactions/Forex/credit/AddCreditModal";
import useModal from "hooks/useModal";

function ClientCredit(props) {
  const clientId = props.clientId;
  const [credit, toggleCredit] = useModal(false);
  const [sizePerPage, setSizePerPage] = useState(10);
  const dispatch = useDispatch();
  const {
    docs,
    loading,
    pagination,
  } = useSelector((state) => ({
    docs: state.creditReducer.credits || [],
    loading: state.creditReducer.loading || false,
    pagination: state.creditReducer
  }));

  const loadTransfers = (page, limit) => {
    dispatch(fetchCredits({
      limit,
      page,
      filteredValues: {
        customerId: clientId,
      },
    }));
  };

  useEffect(() => {
    loadTransfers(1, sizePerPage);
  }, [sizePerPage]);

  // const approve = (transaction) => {
  //   if (selectedTransferType?.toUpperCase() === "WALLET") {
  //     dispatch(approveWalletTransfer(transaction._id));
  //   } else {
  //     dispatch(
  //       approveInternalTransfer({
  //         id: transaction._id,
  //       })
  //     );
  //   }
  //   sleep(1000).then(() => {
  //     loadTransfers(1, sizePerPage);
  //   });
  // };

  // const reject = (transaction) => {
  //   if (selectedTransferType?.toUpperCase() === "WALLET") {
  //     dispatch(rejectWalletTransfer(transaction._id));
  //   } else {
  //     dispatch(
  //       rejectInternalTransfer({
  //         id: transaction._id,
  //       })
  //     );
  //   }
  //   sleep(1000).then(() => {
  //     loadTransfers(1, sizePerPage);
  //   });
  // };

  const columns = [
    {
      dataField: "checkbox",
      text: <input type="checkbox" id="check-all-deposits" onChange={() => checkAllBoxes("check-all-deposits", ".deposit-checkbox")} />
    },
    {
      dataField: "recordId",
      text: props.t("Credit Id")
    },
    {
      dataField: "createdAt",
      text: props.t("Date"),
      formatter: (val) => formatDate(val.createdAt)
    },
    {
      dataField: "amount",
      text: props.t("Amount"),
      formatter: (item) => {
        if (item.amount < 0) {
          return (
            parseFloat(-1 * (item?.amount?.$numberDecimal)) || parseFloat(-1 * (item?.amount)) || "-"
          );
        } else {
          return (
            parseFloat(item?.amount?.$numberDecimal) || parseFloat(item?.amount) || "-"
          );
        }
      }
    },
    {
      dataField: "type",
      text: props.t("Credit Type"),
      formatter: (item) => (
        item.amount < 0
          ?
          "Credit out"
          :
          "Credit in"
      )
    },
    {
      dataField: "customerId",
      text: props.t("Client"),
      formatter: (val) => {
        return (
          <div>
            <span className="no-italics" style={{ fontWeight: "bold" }}>{val.customerId ? `${captilazeFirstLetter(val.customerId.firstName)} ${captilazeFirstLetter(val.customerId.lastName)}` : ""}</span>
          </div>
        );
      }
    },
    {
      dataField: "currency",
      text: props.t("Currency"),

    },
    {
      dataField: "status",
      text: props.t("Status"),
      formatter: (val) => (
        <Badge status={val.status}></Badge>
      )
    },
    {
      dataField: "note",
      text: props.t("Note")
    },
    // {
    //   dataField: "dropdown",
    //   text: props.t("Actions"),
    //   formatter: (val) => (
    //     <UncontrolledDropdown disabled={val?.status === "APPROVED"}>
    //       <DropdownToggle tag="i" className={` ${val?.status === "APPROVED" ? "text-muted" : ""} `} style={{ cursor: "pointer" }}>
    //         <i className="mdi mdi-dots-horizontal font-size-18" />
    //       </DropdownToggle>
    //       <DropdownMenu className="dropdown-menu-end">
    //         <DropdownItem onClick={() => { }} href="#">{props.t("Approve")}</DropdownItem>
    //         <DropdownItem onClick={() => { }} href="#">{props.t("Reject")}</DropdownItem>
    //       </DropdownMenu>
    //     </UncontrolledDropdown>
    //   )
    // }
  ];
  return (
    <React.Fragment>
      <div className="">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardHeader className="d-flex justify-content-between  align-items-center">
                  <CardTitle className="color-primary">
                    {props.t(`Credits (${pagination?.creditsTotalDocs || 0})`)}
                  </CardTitle>
                  {/* <div className="d-flex gap-2 flex-column flex-md-row">
                    <div className="d-inline-block me-2">
                      <AddCreditModal show={credit} toggleShow={toggleCredit} customerId={clientId} />
                    </div>
                  </div> */}
                </CardHeader>
                <CardBody>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table  table-hover "
                      >
                        <Thead className="text-center table-light">
                          <Tr>
                            {columns.map((column, index) =>
                              <Th data-priority={index} key={index}><span className="color-primary">{column.text}</span></Th>
                            )}
                          </Tr>
                        </Thead>
                        {
                          // if deposits is selected then render clientDeposits
                          pagination?.totalDocs === 0 ? (
                              <Tbody>
                                {loading && (
                                  <TableLoader colSpan={4} />
                                )}
                                {!loading && (
                                  <>
                                    <Tr>
                                      <Td
                                        colSpan={"100%"}
                                        className="fw-bolder text-center"
                                        st="true"
                                      >
                                        <h3 className="fw-bolder text-center">
                                          {props.t("There are no records")}
                                        </h3>
                                      </Td>
                                    </Tr>
                                  </>
                                )}
                              </Tbody>
                            ) : (
                              <Tbody
                                style={{
                                  fontSize: "12px",
                                  textAlign: "center",
                                  textTransform: "capitalize",
                                }}
                              >
                                {loading && (
                                  <TableLoader colSpan={4} />
                                )}
                                {!loading &&
                                  docs.map((row, rowIndex) => (
                                    <Tr key={rowIndex}>
                                      {columns.map((column, index) => (
                                        <Td key={`${rowIndex}-${index}`}>
                                          {column.dataField === "checkbox" ? (
                                            <input type="checkbox" />
                                          ) : (
                                            ""
                                          )}
                                          {column.formatter
                                            ? column.formatter(row, rowIndex)
                                            : row[column.dataField]}
                                        </Td>
                                      ))}
                                    </Tr>
                                  ))}
                              </Tbody>
                            )
                        }
                      </Table>
                    </div>
                  </div>
                </CardBody>
                <CustomPagination
                  {...pagination}
                  totalDocs={pagination?.creditsTotalDocs}
                  docs={docs}
                  setSizePerPage={setSizePerPage}
                  sizePerPage={sizePerPage}
                  onChange={loadTransfers}
                />
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(ClientCredit);
