import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import { getColumns } from "./columns";
import {
  Table, Thead, Tbody, Tr, Th, Td
} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { useTranslation } from "react-i18next";
import { AvField, AvForm } from "availity-reactstrap-validation";
import AvFieldSelect from "components/Common/AvFieldSelect";
import TableLoader from "components/Common/Loader";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getAffiliateReports } from "apis/reports";
import CustomPagination from "components/Common/CustomPagination";

export default function Affiliate() {
  const {
    clientId
  } = useParams();
  const [sizePerPage, setSizePerPage] = React.useState(10);
  const [pagination, setPagination] = React.useState({});
  const [data, setData] = React.useState([]);
  const [type, setType] = React.useState("");
  const [columns, setColumns] = React.useState(getColumns(type));
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const getAffiliate = (query) => {
    setLoading(true);
    getAffiliateReports({
      payload: {
        ...query,
      },
      clientId,
    }).then((res) => {
      const {
        docs,
        ...rest
      } = res;
      setData(docs);
      setPagination(rest);
    }).catch((err) => {
      console.log(err);
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    setColumns(getColumns(type));
    getAffiliate({
      page: 1,
      limit: sizePerPage,
      type,
    });
  }, [type, sizePerPage]);

  return (
    <Container fluid className="gx-4 gap-4 d-flex flex-column" style={{
      minHeight: "60vh",
    }}>
      <Card>
        <CardHeader className="d-flex justify-content-between  align-items-center">
          <CardTitle className="color-primary">
            Affiliate Report
          </CardTitle>
        </CardHeader>
        <CardBody>
          <AvForm
            className='p-1'
            onValidSubmit={(e, v) => {
              getAffiliate({
                page: 1,
                limit: 10,
                ...v,
              });
            }}
          >
            <Row>
              <Col className="col-3">
                <AvField
                  name="dateFrom"
                  label={t("Date From")}
                  type="date"
                  errorMessage={t("Select date From")}
                  validate={{ required: { value: true } }}
                />
              </Col>
              <Col className="col-3">
                <AvField
                  name="dateTo"
                  label={t("Date To")}
                  type="date"
                  errorMessage={t("Select date To")}
                  validate={{ required: { value: true } }}
                />
              </Col>
              <Col className="col-3">
                <AvFieldSelect
                  name="type"
                  label="Type"
                  placeholder="Select Type"
                  options={[{
                    value: "profit",
                    label: "Profit Share"
                  }, {
                    value: "commission",
                    label: "Commission"
                  }]}
                  onChange={(e) => setType(e)}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Type is required"
                    }
                  }}
                />
              </Col>
              <Col className='col-1 text-center d-flex justify-content-center align-items-center mt-3'>
                {
                  loading
                    ?
                    <TableLoader />
                    :
                    <Button type="submit" color="primary" className="" disabled={loading}>
                      {t("Search")}
                    </Button>
                }
              </Col>
            </Row>
          </AvForm>              
          <Table
            id="tech-companies-1"
            className="table  table-hover "
          >
            <Thead className="text-center table-light" >
              <Tr>
                {columns?.map((column, index) =>
                  <Th data-priority={index} key={index}>{column.text}</Th>
                )}
              </Tr>
            </Thead>
            <Tbody className="text-center" style={{ fontSize: "13px" }}>
              {loading && <TableLoader colSpan={4} />}
              {!loading && !data && 
                <>
                  <Tr style={{
                    height: "50vh"
                  }}>
                    <Td colSpan={"100%"} className="fw-bolder text-center" >
                      <h3 className="fw-bolder text-center">{t("There are no records")}</h3>
                    </Td>
                  </Tr>
                </>
              }
              {data && data?.map((row, rowIndex) =>
                <Tr key={rowIndex}>
                  {columns?.map((column, index) =>
                    <Td key={`${rowIndex}-${index}`}>
                      {(column.formatter ? column.formatter(row, rowIndex) : row[column.dataField])}
                    </Td>
                  )}
                </Tr>
              )}
            </Tbody>
          </Table>
          <CustomPagination
            {...pagination}
            setSizePerPage={setSizePerPage}
            sizePerPage={sizePerPage}
            onChange={(page, limit) => getAffiliate({
              page,
              limit,
              type,
            })}
            docs={data}
          />
        </CardBody>
      </Card>
    </Container>
  );
}
